.validatorHeadWrapper {
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 768px) {
  .validatorHeadWrapper {
    flex-direction: row;
  }
}

@media only screen and (max-width: 768px) {
  .validatorHeadWrapper {
    flex-direction: column;
  }
}
